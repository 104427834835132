import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  customerSupportDataSelector,
  loggedInUserSelector,
  selectCustomerDetailSelector,
} from 'redux/selectors';

export default function useScheduleEmailModal() {
  const config = useSelector(selectCustomerDetailSelector);
  const loggedInUser = useSelector(loggedInUserSelector);
  const timezone = loggedInUser?.timezone
    ? loggedInUser?.timezone?.value
    : config.timezone;

  const [dateValue, setDateValue] = useState(null);
  const [timeValue, setTimeValue] = useState(null);

  return { dateValue, setDateValue, timeValue, setTimeValue };
}
