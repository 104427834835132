import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';

import MyModal from 'components/modals/modal';
import { responsiveBreakpoints } from 'utils/utils';

function SectionHeader({ heading }) {
  return (
    <Typography
      variant='h3'
      sx={{
        width: '100%',
        paddingBottom: '4px',
        borderBottom: '1px solid var(--secondary-border-color)',
      }}
    >
      {heading}
    </Typography>
  );
}

SectionHeader.propTypes = {
  heading: PropTypes.string.isRequired,
};

export default function Review({
  subject,
  message,
  selectedAssociates,
  selectedBuyers,
  selectedExternalContacts,
  open,
  setOpen,
  totalBuyersSelected,
  uploadedFiles,
  sendButton,
  sendTestEmail,
  scheduleEmail,
}) {
  const [fileName, setFileName] = useState('No File Chosen');
  const [firstTimeRendered, setFirstTimeRendered] = useState(true);
  const { mobile } = responsiveBreakpoints();
  const ConvertStringToHTML = function (str) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(str, 'text/html');
    return doc.body;
  };

  useEffect(() => {
    // document.getElementById('typo').appendChild(ConvertStringToHTML(message));
  }, [message, open]);
  const htmlRegex = /<[^>]*>/;
  const closeModal = (
    <Button variant='outlined' color='primary' onClick={() => setOpen(false)}>
      Close
    </Button>
  );
  const containerRef = useRef(null);

  useEffect(() => {
    if (firstTimeRendered) {
      setFirstTimeRendered(false);
    } else {
      const container = containerRef.current;
      if (!container) {
        return;
      }
      // Set the inner HTML of the container
      container.innerHTML = message;
      document.getElementById('image_container').style.display = 'block';
    }
  }, [open, message, firstTimeRendered]);

  return (
    <MyModal
      open={open}
      setOpen={setOpen}
      size={mobile ? 'fullWidth' : 'medium'}
      title='Preview & Send'
      showCloseIcon
      actions={[closeModal, sendTestEmail, scheduleEmail, sendButton]}
      sx={
        mobile
          ? { height: '100vh', width: '100vw' }
          : { height: '80vh', width: '80vw' }
      }
    >
      <Stack
        spacing={2.5}
        sx={{
          padding: '20px 25px',
          backgroundColor: 'var(--paper-background-color)',
          border: '1px solid var(--secondary-border-color)',
          borderRadius: '6px',
          width: mobile ? '100%' : '70vw',
        }}
      >
        <Stack spacing={1}>
          <SectionHeader heading='To: Buyers' />
          <Stack
            direction='row'
            sx={{
              display: 'inline-block',
              width: 'inherit',
              maxHeight: '70px',
              overflowY: 'scroll',
            }}
          >
            {selectedBuyers.map((buyer, i) => {
              if (i < 15)
                return (
                  <Chip
                    key={i}
                    label={buyer.aname}
                    variant='outlined'
                    sx={{
                      marginBottom: '5px',
                      fontWeight: '700',
                      marginRight: '8px',
                      width: 'fit-content',
                      borderRadius: '4px',
                      height: '30px',
                      backgroundColor: 'rgba(227, 227, 227, 0.5)',
                      borderColor: 'var(--secondary-border-color)',
                    }}
                  />
                );
              if (i == 15)
                return (
                  <Chip
                    label={`And ${totalBuyersSelected - i} More...`}
                    sx={{
                      marginBottom: '5px',
                      fontWeight: '700',
                      marginRight: '8px',
                      width: 'fit-content',
                      borderRadius: '4px',
                      height: '30px',
                      backgroundColor: 'rgba(227, 227, 227, 0.5)',
                      borderColor: 'var(--secondary-border-color)',
                    }}
                  />
                );
            })}
          </Stack>
        </Stack>
        <Stack spacing={1}>
          <SectionHeader heading='To: External Contacts' />
          <Stack
            direction='row'
            sx={{
              display: 'inline-block',
              width: 'inherit',
              maxHeight: '70px',
              overflowY: 'scroll',
            }}
          >
            {selectedExternalContacts.map((externalContact, i) => (
              <Chip
                key={i}
                label={externalContact?.contact_email}
                variant='outlined'
                sx={{
                  marginBottom: '5px',
                  fontWeight: '700',
                  marginRight: '8px',
                  width: 'fit-content',
                  borderRadius: '4px',
                  height: '30px',
                  backgroundColor: 'rgba(227, 227, 227, 0.5)',
                  borderColor: 'var(--secondary-border-color)',
                }}
              />
            ))}
          </Stack>
        </Stack>
        <Stack spacing={1}>
          <SectionHeader heading='CC: Associates' />
          <Stack
            direction='row'
            sx={{
              display: 'inline-block',
              width: 'inherit',
              maxHeight: '70px',
              overflowY: 'scroll',
            }}
          >
            {selectedAssociates.map((associate, i) => (
              <Chip
                key={i}
                label={`${associate.name} + (${associate.email})`}
                variant='outlined'
                sx={{
                  marginBottom: '5px',
                  fontWeight: '700',
                  marginRight: '8px',
                  width: 'fit-content',
                  borderRadius: '4px',
                  height: '30px',
                  backgroundColor: 'rgba(227, 227, 227, 0.5)',
                  borderColor: 'var(--secondary-border-color)',
                }}
              />
            ))}
          </Stack>
        </Stack>
        <Stack spacing={1}>
          <SectionHeader heading='Subject' />
          <Typography variant='body1'>{subject}</Typography>
        </Stack>
        {/* textEditor */}
        <Stack spacing={1}>
          <SectionHeader heading='Message' />
          {Array.isArray(uploadedFiles) && uploadedFiles.length > 0 ? (
            <Stack
              direction='row'
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                rowGap: '8px',
                columnGap: '10px',
              }}
            >
              {uploadedFiles.map((file) => {
                return (
                  <Stack
                    key={`review_file_${file?.id}`}
                    direction='row'
                    spacing={0.5}
                    alignItems='center'
                  >
                    {file?.filetype == 'file' ? (
                      <AttachFileOutlinedIcon
                        color='#1189F0'
                        style={{
                          transform: 'scaleX(-1) rotate(-45deg)', // Adjust the angle as needed
                          color: '#1189F0',
                          width: 'auto',
                          height: '18px',
                        }}
                      />
                    ) : (
                      <InsertPhotoOutlinedIcon
                        color='#1189F0'
                        style={{
                          color: '#1189F0',
                          width: 'auto',
                          height: '18px',
                        }}
                      />
                    )}

                    <Typography variant='body2' color='#1189F0'>
                      {file?.actual_filename}
                    </Typography>
                  </Stack>
                );
              })}
            </Stack>
          ) : null}

          {/* check if message is string or html element */}
          {htmlRegex.test(message) ? (
            <div
              id='image_container'
              ref={containerRef}
              style={{ display: 'none' }}
            />
          ) : (
            <Typography
              sx={{
                lineHeight: 1.5,
                fontSize: '15px',
                whiteSpace: 'pre-line',
                wordWrap: 'break-word',
                overflowWrap: 'break-word',
                '& p': { margin: 0 },
              }}
              id='typo'
              variant='body1'

              // dangerouslySetInnerHTML={{ __html: message }}
            >
              {/* <pre>{message}</pre> */}
              {message}
            </Typography>
          )}
        </Stack>
      </Stack>
    </MyModal>
  );
}

Review.propTypes = {
  subject: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  selectedAssociates: PropTypes.array.isRequired,
  selectedBuyers: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  totalBuyersSelected: PropTypes.number,
  uploadedFiles: PropTypes.array,
  sendButton: PropTypes.node,
  scheduleEmail: PropTypes.node,
  sendTestEmail: PropTypes.node,
  selectedExternalContacts: PropTypes.array,
};
Review.defaultProps = {
  subject: '',
  message: '',
  selectedAssociates: [],
  selectedBuyers: [],
  open: false,
  setOpen: () => {},
  totalBuyersSelected: 0,
  uploadedFiles: [],
  sendButton: <></>,
  selectedExternalContacts: [],
};
