export const DepartmentStyles = {
  appBar: {
    position: 'relative',
    height: '50px',
    maxHeight: '50px',
    backgroundColor: 'var(--paper-background-color)',
    boxShadow: 0,
    borderTop: '1px solid rgba(204, 204, 204, 0.6)',
    borderBottom: '1px solid rgba(204, 204, 204, 0.6)',
    justifyContent: 'center',
  },
  departmentListContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    width: `${0.7 * screen.width}`,
    overflow: 'hidden',
  },
  departmentBtn: {
    height: '40px',
    borderBottom: '2px solid transparent',
    color: 'var(--primary-font-color)',
    fontWeight: 700,
    fontSize: '12px',
    position: 'relative',
    width: 'fit-content',
    alignItems: 'center',
    justifyContent: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    padding: '6px 8px 6px 8px',
  },
  departmentImg: {
    width: '28px',
    height: '26px',
    objectFit: 'contain',
    marginRight: '4px',
  },
  selectedDepartmentBtn: {
    borderBottom: '2px solid',
    borderColor: 'primary.main',
    color: 'primary.main',
    background: '#f4f5f7',
    fontWeight: 600,
  },
  lookBooksButton: {
    padding: '5px 12px 4px 12px',
    marginRight: '0px',
    height: '24px',
    width: '165px',
  },
};

export const MegaMenuStyles = {
  container: {
    width: '700px',
    minHeight: '300px',
    maxHeight: '426px',
    backgroundColor: 'var(--paper-background-color)',
    position: 'absolute',
    top: '55px',
    left: 0,
    zIndex: 999,
    boxShadow: '0 3px 18px -12px rgba(0, 0, 0, 0.9)',
    boxSizing: 'border-box',
  },
  megaMenuHeader: {
    padding: '14px 0px 14px 0px',
    backgroundColor: 'var(--modal-header-color)',
  },
  divider: { marginBottom: '5px' },
  menuContainer: {
    padding: '0px 0 10px 10px',
    backgroundColor: 'var(--paper-background-color)',
  },
  collectionGrid: {
    minHeight: '300px',
    maxHeight: '350px',
    overflowY: 'scroll',
    paddingTop: '0',
    margin: '0',
    width: '300px',
    cursor: 'pointer',
  },
  categoriesGrid: {
    minHeight: '300px',
    maxHeight: '350px',
    overflowY: 'scroll',
    width: '250px',
  },
  deliveriesGrid: {
    minHeight: '300px',
    overflowY: 'scroll',
    padding: '0px 0 5px 4px',
    width: '250px',
  },
  collectionGridItem: {
    paddingLeft: '14px',
  },
  deliveryGridItem: {
    paddingLeft: '10px',
  },
};

export const CollectionStyles = {
  container: {},
  loader: {},
  megaMenuCollection: {
    padding: '3px 4px 8px 4px',
    margin: '0',
    borderBottom: '2px solid transparent',
    display: 'block',
    textAlign: 'left',
    fontSize: '12px',
    color: '#4d4663',
    fontWeight: 400,
    '&:hover': {
      color: 'primary.main',
      background: '#f4f5f7',
      fontWeight: '700',
    },
  },
  selectedCollection: {
    color: 'primary.main',
    background: '#f4f5f7',
    fontWeight: '700',
  },
};

export const DeliveriesStyles = {
  megaMenuDeliveries: {
    padding: '7px 0 5px 5px',
    height: 'fit-content',
    display: 'block',
    textAlign: 'left',
    cursor: 'pointer',
    '&:hover': {
      color: 'primary.main',
      background: '#f4f5f7',
      fontWeight: 600,
    },
  },
  selectedDeliverie: {
    color: 'primary.main',
    background: '#f4f5f7',
    fontWeight: 600,
  },
  deliveryTypo: { pointerEvents: 'none' },
};

export const CategoriesStyles = {
  megaMenuCatagories: {
    padding: '7px 0 5px 5px',
    borderBottom: '2px solid transparent',
    display: 'block',
    textAlign: 'left',
    width: '100%',
    fontWeight: 400,
    '&:hover': {
      color: 'primary.main',
      background: '#f4f5f7',
      fontWeight: 700,
    },
  },
};

export const DepartmentDrawerMenu = {
  container: {
    minHeight: '610px',
    maxHeight: '610px',
    backgroundColor: 'var(--paper-background-color)',
    padding: '0px 10px 0px 10px',
    position: 'absolute',
    top: 0,
    right: 0,
    width: '320px',
    zIndex: 999,
    boxShadow: '0 0 18px -12px rgba(0, 0, 0, 0.9)',
    boxSizing: 'border-box',
  },
  subContaienr: {
    position: 'relative',
    overflow: 'auto',
    maxHeight: 'inherit',
  },
  drawerHead: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    height: '45px',
    padding: '4px 0',
    backgroundColor: 'var(--paper-background-color)',
  },

  departmentListContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    gap: '4px',
    width: '100%',
  },
  departmentBtn: {
    height: '40px',
    width: '100%',
    color: 'var(--primary-font-color)',
    fontWeight: 700,
    fontSize: '13px',
    justifyContent: 'left',
    borderRadius: '4px',
    '&:hover': {
      color: 'primary.main',
      background: '#f4f5f7',
    },
  },
  selectedDepartmentBtn: {
    borderBottom: '2px solid',
    borderColor: 'primary.main',
    color: 'primary.main',
    background: '#f4f5f7',
  },
  departmentImg: {
    width: '28px',
    height: '26px',
    objectFit: 'contain',
    marginRight: '4px',
  },
};
