import React from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';

import MyModal from '../modal';
import SelectOld from '../../select/select.old';
import useScheduleEmailModal from './schedule-email-modal-hooks';
import { responsiveBreakpoints } from 'utils/utils';
import CustomDatePicker from 'components/date-picker';

import { style } from '../../../pages/customers/components/buyers-table/styles';
import { TextField } from '@mui/material';
import { showSnackbar } from '../../snackbar/actions';
import { useDispatch } from 'react-redux';

export default function ScheduleEmailModal({
  open,
  setOpen,
  timeZoneOptions,
  timeZoneValue,
  setTimeZoneValue,
  setSendScheduleEmail,
  setScheduleDetails,
  sendEmail,
  setIsLoading,
  setShowPreview,
  setShowSendingEmailAlertModal,
}) {
  const { dateValue, setDateValue, timeValue, setTimeValue } =
    useScheduleEmailModal();
  const { mobile } = responsiveBreakpoints();
  const dispatch = useDispatch();

  const add = (
    <Button
      variant='contained'
      onClick={async () => {
        setSendScheduleEmail(true);

        let detailsObj = {
          timeZoneValue: timeZoneValue,
          dateValue: dateValue,
          timeValue: timeValue,
          task: 'scheduleEmail',
        };

        setScheduleDetails(detailsObj);

        let response = await sendEmail(true, detailsObj);
        if (response?.data?.status) {
          setIsLoading(false);
          setShowPreview(false);
          setShowSendingEmailAlertModal(true);
        } else {
          setIsLoading(false);
          dispatch(
            showSnackbar({
              snackbarMessage: 'Failed To Send Email',
              type: 'error',
              snackbarAlign: {
                vertical: 'top',
                horizontal: 'right',
              },
            })
          );
        }
      }}
    >
      Schedule Email
    </Button>
  );

  const close = (
    <Button
      variant='outlined'
      color='secondary'
      onClick={(e) => {
        e.preventDefault();
        setOpen(false);
      }}
    >
      Cancel
    </Button>
  );

  return (
    <MyModal
      open={open}
      setOpen={setOpen}
      size='small'
      title='Schedule Email'
      showCloseIcon
      actions={[close, add]}
      sx={
        mobile
          ? { width: '100%', height: '100%', padding: '12px' }
          : { width: '30vw' }
      }
    >
      <Stack spacing={2} direction='column'>
        <Grid item xs={12} sm={6} sx={{ paddingRight: '16px' }}>
          <SelectOld
            label='Select Time Zone'
            size='small'
            options={timeZoneOptions}
            placeholder='Select Time Zone'
            allowRemoveIcon={false}
            value={timeZoneValue}
            setValue={(val) => {
              setTimeZoneValue(val);
            }}
          />
        </Grid>

        <Grid container rowGap='16px'>
          <Grid item xs={12} sm={6}>
            <CustomDatePicker
              label={'Schedule Date'}
              showPopupActions
              sx={{
                ...style.tableCellTextField,
                '& .MuiFormControl-root .MuiOutlinedInput-root': {
                  height: '28px',
                },
                width: '130px',
                // backgroundColor: 'inherit',
                backgroundColor: (() => {})(),
              }}
              value={dateValue}
              onChange={(e) => {
                let dateValues = '';
                if (e) {
                  dateValues = e
                    .toLocaleDateString('en-GB')
                    .split('/')
                    .reverse()
                    .join('-');
                }

                setDateValue(dateValues);
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label='Time'
              onChange={(e) => {
                setTimeValue(e.target.value);
              }}
              value={timeValue}
            />
          </Grid>
        </Grid>
      </Stack>
    </MyModal>
  );
}

ScheduleEmailModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  timeZoneOptions: PropTypes.object,
  totalProducts: PropTypes.number,
  timeZoneValue: PropTypes.array,
  setTimeZoneValue: PropTypes.func,
  setSendScheduleEmail: PropTypes.func,
  setScheduleDetails: PropTypes.func,
  sendEmail: PropTypes.func,
  setIsLoading: PropTypes.func,
  setShowPreview: PropTypes.func,
  setShowSendingEmailAlertModal: PropTypes.func,
};
